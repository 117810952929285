import refineUrl from '../common/helpers/refineUrl';
import { CONSUMER_ID_HEADER } from '.';
import asyncLogger from '../common/logger';
import { cache, generateKey } from '../common/StaticCache';
import { RESOURCE } from '../common/types/CmsEntities';

// Next.js no longer recommends using the runtimeConfig option,
// as this does not work with the standalone output mode.
// Instead, Next.js recommends incrementally adopting the App Router
// and using process.env.MY_VARIABLE directly.
export const environment = (process.env.ENV_NAME || '').trim();
export const envStoreId = (process.env.STORE_ID || '').trim();
export const campaignKey = (process.env.LT_CAMPAIGN_KEY || '').trim();
export const ltTest = (process.env.LT_TEST || '').trim();
export const disablePwa = process.env.DISABLE_PWA;
export const testIPs = (process.env.TEST_IPS || '')
    .split(',').map(ip => ip.trim());
export const sellLeadTimeoutMs = process.env.SELL_LEAD_TIMEOUT_MS || 3000;
export const apiUrl = (process.env.API_URL || '').trim();
export const cmsUrl = (process.env.CMS_URL || '').trim();

export const toStoreId = (id: string) => {
    const storeIdMap: { [key: string]: string } = {
        TEST: 'Test',
        ORDINARY: 'Primary',
    };

    return storeIdMap[id] || storeIdMap.TEST;
};

const storeId = toStoreId(envStoreId);

export interface ConfigRecord {
    apiUrl: string;
    baseUrl: string;
    consumerId: string;
    cmsApiUrl: string;
    consumerName: string;
    faKitToken: string;
    disablePwa: boolean;
    storeId: string;
    recaptchaId: string;
    isTest: boolean;
    whatsAppSupportPhoneNumber: string | undefined;
    eSigAuthorizationRequirement: boolean;
    errorScreens: ErrorScreen[] | [];
    consumerOperationsDetails: {
        phoneNumber: string;
        supportEmail: string;
        officeHours: {
            timeZone: string;
            start: {
                hour: number;
                minute: number;
            };
            end: {
                hour: number;
                minute: number;
            };
        };
    } | null;
    trustpilot: {
        isAutoRedirect: boolean | null;
        autoRedirectDelay: number | null;
    } | null;
    paymentAmountConfirmationThreshold: number;
    accurateGeolocation: boolean;
    sellLeadUrl: string | undefined;
    sellLeadEnable: boolean;
    enableLandingPage: boolean;
    dbSigAuthorizationRequirement: boolean;
    allowedStates: string[];
    trustpilotConfigured: boolean;
}

export interface ErrorScreen {
    code: string;
    name: string;
    priority: number;
    description?: string;
    enabled?: boolean;
}

interface WebsiteConsumerConfigs {
    consumerId: string;
    recaptchaId: string;
    whatsAppSupportPhoneNumber: string | undefined;
    supportPhoneNumber: string;
    supportEmail: string;
    eSigAuthorizationRequirement: boolean;
    errorScreens: ErrorScreen[] | [];
    paymentAmountConfirmationThreshold: number;
    accurateGeolocation: boolean;
    sellLeadUrl: string | undefined;
    sellLeadEnable: boolean;
    enableLandingPage: boolean;
    dbSigAuthorizationRequirement: boolean;
    allowedStates: string[];
    trustpilotConfigured: boolean;
    trustpilot: {
        isAutoRedirect: boolean | null;
        autoRedirectDelay: number | null;
    } | null;
}

const fetchConsumerConfigs = async (
    apiUrl: string,
    baseUrl: string,
): Promise<WebsiteConsumerConfigs> => {
    const url = `${apiUrl}/consumer-id/${encodeURIComponent(baseUrl)}`;
    const res = await fetch(url);

    if (!res.ok) {
        console.error('Response is not ok for baseUrl: ', baseUrl);
        throw new Error('Response is not ok');
    }

    const result = await res.json();

    if (!result || !result.consumerId) {
        throw new Error('Couldn\'t get consumer id');
    }

    const {
        consumerId: decodedConsumerId,
        recaptchaId,
        whatsAppSupportPhoneNumber,
        supportPhoneNumber,
        supportEmail,
        eSigAuthorizationRequirement,
        errorScreens,
        paymentAmountConfirmationThreshold,
        accurateGeolocation,
        sellLeadUrl,
        sellLeadEnable,
        enableLandingPage,
        dbSigAuthorizationRequirement,
        allowedStates,
        trustpilotConfigured,
        trustpilot,
    } = result;

    const [, consumerId] = Buffer.from(decodedConsumerId, 'base64')
        .toString()
        .split(':');

    return {
        consumerId,
        recaptchaId,
        whatsAppSupportPhoneNumber,
        supportPhoneNumber,
        supportEmail,
        eSigAuthorizationRequirement,
        errorScreens,
        paymentAmountConfirmationThreshold,
        accurateGeolocation,
        sellLeadUrl,
        sellLeadEnable,
        enableLandingPage,
        dbSigAuthorizationRequirement,
        allowedStates,
        trustpilotConfigured,
        trustpilot,
    };
};

export interface WebsiteMetadata {
    consumerName: string;
    faKitToken: string;
}

const fetchWebsiteMetadata = async (
    consumerId: string,
    cmsApiUrl: string,
): Promise<WebsiteMetadata | undefined> => {
    try {
        const cacheKey = generateKey(consumerId, 'website-metadata');
        const metadata = await cache.get(cacheKey);

        if (metadata) {
            return metadata;
        }

        // noinspection SpellCheckingInspection
        const res = await fetch(
            `${ cmsApiUrl }/${ RESOURCE.websiteMetadata }`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Origin': '*',
                    [CONSUMER_ID_HEADER]: consumerId,
                },
            },
        );
        const { data } = await res.json();

        if (!data || !data.length) {
            return;
        }

        const [attributes] = data;

        if (!attributes) {
            return;
        }

        const { name: consumerName, faKitToken } = attributes;
        const result = { consumerName, faKitToken } as WebsiteMetadata;

        if (consumerName && faKitToken) {
            await cache.set(cacheKey, result);
        }

        return result;
    } catch (err) {
        asyncLogger.error((err as any).message);

        return ;
    }
};

const ConfigError = (message: string): Error => {
    return new Error(`${message} is not configured or cannot be loaded`);
};

const RX_VER = /^v\d+$/;

const loadConfig = async (
    baseUrl: string,
    version?: string,
): Promise<ConfigRecord> => {
    if (!version || !RX_VER.test(version)) {
        version = '';
    }

    if (!apiUrl) {
        throw ConfigError('API URL');
    }

    const cmsApiUrl = `${cmsUrl}/api`;
    const {
        consumerId,
        recaptchaId,
        whatsAppSupportPhoneNumber,
        supportPhoneNumber,
        supportEmail,
        eSigAuthorizationRequirement,
        errorScreens,
        paymentAmountConfirmationThreshold,
        accurateGeolocation,
        sellLeadUrl,
        sellLeadEnable,
        enableLandingPage,
        dbSigAuthorizationRequirement,
        allowedStates,
        trustpilotConfigured,
        trustpilot,
    } = await fetchConsumerConfigs(apiUrl, baseUrl);

    if (!consumerId) {
        throw ConfigError('Consumer ID');
    }

    const websiteMetadata = await fetchWebsiteMetadata(
        consumerId,
        cmsApiUrl,
    ) || {} as any;

    if (!websiteMetadata) {
        asyncLogger.warn('Website metadata was not loaded!');
    }

    return {
        apiUrl,
        baseUrl,
        consumerId,
        cmsApiUrl,
        storeId,
        disablePwa,
        recaptchaId,
        whatsAppSupportPhoneNumber,
        errorScreens,
        consumerOperationsDetails: {
            phoneNumber: supportPhoneNumber,
            supportEmail: supportEmail,
            officeHours: {
                timeZone: 'America/New_York',
                start: {
                    hour: 7,
                    minute: 0,
                },
                end: {
                    hour: 18,
                    minute: 0,
                },
            },
        },
        trustpilot,
        ...websiteMetadata,
        eSigAuthorizationRequirement,
        paymentAmountConfirmationThreshold,
        accurateGeolocation,
        sellLeadUrl,
        sellLeadEnable,
        enableLandingPage,
        trustpilotConfigured,
        dbSigAuthorizationRequirement,
        allowedStates,
    };
};

const addIsTest = (target: ConfigRecord) => {
    return Object.assign(target, { isTest: process.env.ENV_NAME === 'qa' });
};

const getRuntimeConfig = async (
    baseUrl: string | undefined,
    version?: string,
): Promise<ConfigRecord> => {
    if (!baseUrl) {
        throw new Error('Base URL cannot be extracted.');
    }

    const refinedBaseUrl = refineUrl(baseUrl);
    const storeRecord = await cache.get(refinedBaseUrl);

    if (storeRecord) {
        return addIsTest(storeRecord);
    }

    const loadedConfig = await loadConfig(baseUrl, version);

    await cache.set(refinedBaseUrl, loadedConfig);

    return addIsTest(loadedConfig);
};

export default getRuntimeConfig;
