import axios, { AxiosResponseHeaders, Method } from 'axios';
import type { NextApiRequest, NextApiResponse } from 'next';
import { getClientIp } from 'request-ip';
import { CONSUMER_ID_HEADER } from '../../config';
import getConfigWithRequest from '../helpers/getConfigWithRequest';
import verifyMethod, { RestMethod } from '../helpers/rest';
import Redis from 'ioredis';
import { CACHE_HOST, CACHE_PORT } from '../helpers/cluster';
import { dontRefreshTokenCookieName, isAuthError } from './common';
import asyncLogger, { ILogger } from '../logger';
import { parseSetCookieHeader } from '../helpers/parseCookie';
import HttpProtect, { VerificationStatus } from '@imqueue/http-protect/src';
import { refreshToken, Headers } from '@credit-sense/auth-utils';
import { CACHE_KEY_PREFIX } from '../constants';

const RX_NL = /\r?\n/g;

export interface GqlProxyOptions {
    req: NextApiRequest;
    res: NextApiResponse;
    method?: Method;
    variables?: any;
}

export interface GqlClientOptions {
    req?: NextApiRequest;
    variables?: any;
    withClientIp?: boolean;
}

export default class GqlClient {
    private redis?: Redis;
    private protector?: HttpProtect;
    private readonly tokenRefreshedSubscriptions:
        {[id: string]: ((token: string) => void)[]} = {};

    public constructor(
        private logger: ILogger = asyncLogger,
    ) {
        if (typeof window !== 'undefined') {
            return;
        }

        this.redisConnect().catch();
    }

    private async redisConnect() {
        if (this.redis) {
            return true;
        }

        try {
            this.redis = new Redis(CACHE_PORT, CACHE_HOST);
            this.redis
                .client('SETNAME', 'GqlClient:commands')
                .catch((e) => this.logger.error(
                    'GqlClient CLIENT SETNAME error:',
                    e,
                ));

            return true;
        } catch (e) {
            this.logger.error('GqlClient error:', e);
        }

        return false;
    }

    private async protect(
        req: NextApiRequest,
        res: NextApiResponse,
    ) {
        if (!await this.redisConnect()) {
            return false;
        }

        const {
            status,
            httpCode,
        } = await this.getProtector().verify(req);

        if (status !== VerificationStatus.SAFE) {
            res.status(httpCode);
            res.end();

            return true;
        }

        return false;
    }

    public getProtector() {
        if (!this.redis) {
            throw new Error('Redis connection required!');
        }

        this.protector = this.protector || new HttpProtect({
            redis: this.redis,
            redisPrefix: `${ CACHE_KEY_PREFIX }:protect`,
        });

        return this.protector;
    }

    public async request<T = any>(
        query: string,
        { req, res, method, variables }: GqlProxyOptions,
    ): Promise<void> {
        if (await this.protect(req, res)) {
            return ;
        }

        if (!verifyMethod(req, res, method || RestMethod.POST) || !this.redis) {
            return ;
        }

        try {
            // eslint-disable-next-line prefer-const
            let { data, headers, errors } = await GqlClient.gqlRequest<T>(
                query,
                { req, variables },
            );

            const cookies = parseSetCookieHeader(headers);
            let authToken = cookies?.authToken;

            if (isAuthError(errors)) {
                const needToRefreshToken = req.cookies.authToken &&
                    !req.cookies[dontRefreshTokenCookieName];

                if (needToRefreshToken && await this.refreshToken(req, res)) {
                    authToken = req.cookies.authToken!;
                    const repeated = await GqlClient.gqlRequest<T>(
                        query,
                        { req, variables },
                    );

                    data = repeated.data;
                    errors = repeated.errors;
                }
            } else {
                GqlClient.updateCookies(res, headers);
            }

            (data as any).authToken = authToken;

            res.status(200).json({ data, errors });
        } catch (e) {
            res.status(500).json(e);
        }
    }

    public static async gqlRequest<T = any>(
        query: string,
        options?: GqlClientOptions,
    ): Promise<{ data: T; errors: any[]; headers: AxiosResponseHeaders }> {
        const { req, variables } = options || {};
        const { apiUrl: url, consumerId } = await getConfigWithRequest(req);
        const inputHeaders = {};

        if (req) {
            Object.assign(inputHeaders, {
                'X-Customer-Ip': getClientIp(req) || '',
                'User-Agent': req.headers['user-agent'],
                'Cookie': req.headers['cookie'],
                'Origin': req.headers['origin'],
                'X-Customer-Referer': req.headers['referer'],
                'X-Customer-Accept-Encoding': req.headers['accept-encoding'],
                'X-Customer-Accept-Language': req.headers['accept-language'],
            });

            if (req.headers['x-ws-domain']) {
                Object.assign(inputHeaders, {
                    'X-Ws-Domain': req.headers['x-ws-domain']?.toString(),
                });
            }

            asyncLogger.info(
                'GqlClient request: client IP=%s, method=%s, query=%s',
                getClientIp(req) || '',
                req.method,
                (query || '').replace(RX_NL, '\\n'),
            );
        }

        const { data, headers } = await axios({
            url,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                [CONSUMER_ID_HEADER]: consumerId,
                ...inputHeaders,
            },
            data: { query, variables },
        });

        return { ...data, headers };
    }

    public async refreshToken(
        req: NextApiRequest,
        res: NextApiResponse,
    ): Promise<boolean> {
        const token = req.cookies.authToken!;
        const { refreshedToken, headers } = await refreshToken(token);

        if (refreshedToken) {
            if (headers) {
                GqlClient.updateCookies(res, headers);
            }

            const oldToken = req.cookies.authToken!;
            const cookieHeader = req.headers.cookie;
            const getTokenCookie = (token: string) => `authToken=${ token }`;

            req.cookies.authToken = refreshedToken;
            req.headers.cookie = cookieHeader!.replace(
                getTokenCookie(oldToken),
                getTokenCookie(refreshedToken),
            );
        }

        return !!refreshedToken;
    }

    private static updateCookies(res: NextApiResponse, headers: Headers) {
        const setCookieHeader = headers['set-cookie'];
        if (setCookieHeader) {
            res.setHeader('set-cookie', setCookieHeader);
        }
    }
}

const scope: {gqlClient?: GqlClient} = (
    typeof window === 'undefined' ? global : window
) as any;

if (!scope.gqlClient) {
    scope.gqlClient = new GqlClient();
}

const gqlClient = scope.gqlClient;

export { gqlClient };
