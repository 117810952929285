import React, { useEffect, useState } from 'react';
import NextApp, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import absoluteUrl from 'next-absolute-url';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import '../styles/globals.css';
import { useRouter } from 'next/router';
import getRuntimeConfig, { ConfigRecord } from '../config/runtimeConfig';
import ConfigProvider from '../common/hooks/ConfigProvider';
import { accessDenied } from '../common/helpers/validation';
import './styles-light.css';
import ThemeComponent from '../components/ThemeComponent';
import { AvailableThemes, KnownThemes } from '../common/themes';
import Cookie from '../common/cookie';
import asyncLogger from '../common/logger';
import OfflineStore from '../common/OfflineStore';
import { AuthorizedContext } from '../common/hooks/useAuthorizedContext';
import MenuProvider from '../common/hooks/MenuProvider';
import AccountInfoProvider from '../common/hooks/AccountInfoProvider';
import {
    serviceWorkerInitializer,
} from '../common/helpers/service-worker/swInitializer';
import {
    serviceWorkerRegistration as swRegistration,
} from '../common/helpers/service-worker/swRegistration';
import { SwResources } from '../common/helpers/service-worker/swCommon';
import {
    getRuntimeEnvironmentType,
    RuntimeEnvironmentType,
} from '../common/hooks/useRuntimeEnvironmentType';
import { isAuthorized } from '../common/helpers/login';
import { delayedThrottle } from '../common/helpers/throttle';
import { invalidateSwCache } from '../common/helpers/service-worker/swCache';
import showUpdaterScreen from '../components/UpdaterScreen';
import SwLock from '../common/helpers/service-worker/SwLock';
import { fireGtmEvent, getGtmStepEvent, hasGtmStep } from '../common/gtmEvents';
import { PageProps } from '../common/types/CmsEntities';
import {
    mobileBgColor,
    updatePwaThemeColor,
} from '../common/helpers/themeBgColor';
import { roboto } from '../common/helpers/style';
import MaintenanceComponent from '../components/MaintenanceComponent';
import { getEnabledScreen } from '../common/helpers/getEnabledScreen';
import NotificationsProvider from '../common/hooks/NotificationsProvider';
import assignDomainString from '../common/helpers/csp';
import { Customer } from '../common/types/Customer';
import { trackRequest } from '../common/helpers/tracking';
import uuid from '../common/helpers/uuid';
import { ApiClient } from '../common/helpers';
import { platformHelper } from '../common/helpers/platformHelper';
import { CACHE_HOST, CACHE_PORT } from '../common/helpers/cluster';
import { initAuthUtils } from '@credit-sense/auth-utils';
import GqlClient from '../common/clients/GqlClient';

type ComponentPageLayoutProps = {
    children: React.ReactNode;
};

export type ComponentWithPageLayout = AppProps & {
    Component: AppProps['Component'] & {
        PageLayout?: (props: ComponentPageLayoutProps) => any;
    };
    pageProps: PageProps;
    config: ConfigRecord;
    themeMode: keyof AvailableThemes;
    userAgent?: string;
    authorized?: boolean;
    swResources: SwResources;
    ww?: number;
    wh?: number;
    deviceId?: string;
    tokenExists: boolean;
};

/**
 * The following piece of code is required to handle problem occurred when
 * user has an outdated app version at runtime, while server has been updated
 * to a newer release version.
 */
if (typeof window !== 'undefined') {
    if (!(window as any).__autoReloadInitialized__) {
        (window as any).__autoReloadInitialized__ = true;

        // release lock on window close to avoid deadlocks
        window.addEventListener('close', async () => {
            await new SwLock('prefetch').release();

            if (hasGtmStep()) {
                fireGtmEvent(getGtmStepEvent());
            }
        });

        swRegistration.on('fetch', async () => {
            const serverId = Cookie.get('buildId');
            const clientId = window.__NEXT_DATA__.buildId;

            if (!(serverId && serverId !== clientId)) {
                return ;
            }

            window.__NEXT_DATA__.buildId = serverId;

            showUpdaterScreen();

            const target = (window as any).__NEXT_URL__;
            const urls = [location.href];

            if (target) {
                urls.push(target);
            }

            const invalidatedUrls = await invalidateSwCache(urls)
                .catch() || []
            ;

            if (target && invalidatedUrls.includes(target)) {
                location.href = target;
            } else {
                location.reload();
            }
        });
    }
}

function locationError(resolve: any, reject: any) {
    return (error: any) => {
        if (error.code == error.TIMEOUT) {
            getLocation(false).then(resolve).catch(reject);
        } else {
            reject(error);
        }
    };
}

async function getLocation(enableHighAccuracy: boolean = true) {
    if (navigator && navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                resolve,
                enableHighAccuracy
                    ? locationError(resolve, reject)
                    : reject,
        { maximumAge: 600000, timeout: 10000, enableHighAccuracy},
            );
        });
    }

    return null;
}

function App({
    Component,
    pageProps,
    config,
    themeMode,
    userAgent,
    authorized: inputAuthorized,
    swResources,
    ww,
    wh,
    deviceId,
    tokenExists,
}: ComponentWithPageLayout): React.JSX.Element {
    const disablePwa = config ? config.disablePwa : true;
    const highestPriorityEnabledScreen = getEnabledScreen(
        config?.errorScreens || [],
        true,
    );
    const [authorized, setAuthorized] = useState(inputAuthorized);
    const [
        prefThemeMode,
        setPrefThemeMode,
    ] = useState<KnownThemes>(themeMode || KnownThemes.light);
    const router = useRouter();
    const [
        customer,
        setCustomer,
    ] = useState<Customer | null>(null);

    useEffect(() => {
        if (tokenExists) {
            setAuthorized(true);
            OfflineStore.set(OfflineStore.USER_AUTHORIZED, true);
        } else {
            setAuthorized(false);
            OfflineStore.del(OfflineStore.USER_AUTHORIZED);
        }
      }, [tokenExists]);

    useEffect(() => {
        // noinspection JSUnresolvedReference
        const clarity: (...args: any[]) => any = (window as any)?.clarity;

        if (customer?.id) {
            clarity('identify', customer.id);
            clarity('set', 'customerId', customer.id);
        }
    }, [customer?.id]);

    useEffect(() => {
        // noinspection JSUnresolvedReference
        const clarity: (...args: any[]) => any = (window as any)?.clarity;

        if (customer?.currentLoan?.id) {
            clarity('set', 'loanId', customer.currentLoan.id);
        }
    }, [customer?.currentLoan?.id]);

    useEffect(() => {
        // noinspection JSUnresolvedReference
        const clarity: (...args: any[]) => any = (window as any)?.clarity;

        if (deviceId) {
            clarity('set', 'deviceId', deviceId);
        }
    }, [deviceId]);

    useEffect(() => {
        if (themeMode) {
            OfflineStore.set(OfflineStore.THEME, themeMode, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themeMode]);

    useEffect(() => {
        const storedAuthorized = OfflineStore.get<boolean>(
            OfflineStore.USER_AUTHORIZED,
        );

        if (typeof storedAuthorized === 'boolean') {
            setAuthorized(storedAuthorized);
        }

        const runtimeEnvironmentType = getRuntimeEnvironmentType();
        const refresh = delayedThrottle(() => {
            const nextPage = (window as any).__NEXT_URL__;
            const currentPage = router.asPath;

            if (!nextPage || (currentPage === nextPage)) {
                router.replace(currentPage).catch();
            } else if (refresh.context.timeout) {
                clearTimeout(refresh.context.timeout);
            }
        }, 1000);
        const onStartNavigate = (url: string) => {
            (window as any).__NEXT_URL__ = url;
        };

        router.events.on('routeChangeStart', onStartNavigate);
        swRegistration.on('refresh', refresh.callback);

        if (runtimeEnvironmentType === RuntimeEnvironmentType.wrapper) {
            swRegistration.registerWrapper();
        } else {
            (async () => {
                let pos: any | null = null;

                if (config.accurateGeolocation) {
                    try {
                        pos = await getLocation();
                    } catch(e) {
                        console.error('Failed to retrieve location:', e);
                    }
                }

                ApiClient.request('/trackLocation', { body: {
                    clientDate: new Date(),
                    ...pos?.coords
                        ? {
                            latitude: pos.coords.latitude,
                            longitude: pos.coords.longitude,
                            accuracy: pos.coords.accuracy,
                        }
                        : {},
                } }).catch(e => console.error('Failed to track location:', e));

                let mixpanel = (window as any).mixpanel;
                if (!mixpanel) {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    mixpanel = (window as any).mixpanel;
                }

                mixpanel.track('Page Viewed', {
                    'Page URL': window.location.href,
                    'Page Name': document.title,
                });

                ApiClient.request('/trackClient', { body: {
                    clientDate: new Date(),
                    params: {
                        mode: 'PWA',
                        pwaMode: platformHelper.isStandalone
                            ? 'app'
                            : 'browser',
                        width: window.screen.width.toString(),
                        height: window.screen.height.toString(),
                        availWidth: window.screen.availWidth.toString(),
                        availHeight: window.screen.availHeight.toString(),
                        pixelRatio: (window.devicePixelRatio || 1).toString(),
                        mixpanelDistinctId:
                            mixpanel?.get_distinct_id?.() || undefined,
                    },
                } }).catch(e => console.error('Failed to track client:', e));
            })();
        }

        if (!disablePwa && 'serviceWorker' in navigator) {
            swRegistration
                .registerServiceWorker(swResources, runtimeEnvironmentType)
                .catch(asyncLogger.error)
            ;
        }

        const change = (key: string) => {
            if (key === OfflineStore.THEME) {
                updatePwaThemeColor(mobileBgColor());
            } else if (key === OfflineStore.USER_AUTHORIZED) {
                setAuthorized(
                    OfflineStore.get<boolean>(OfflineStore.USER_AUTHORIZED)
                    || false,
                );
            }
        };

        OfflineStore.on('change', change);

        Cookie.set('ww', window.innerWidth + '');
        Cookie.set('wh', window.innerHeight + '');

        window.addEventListener('resize', () => {
            Cookie.set('ww', window.innerWidth + '');
            Cookie.set('wh', window.innerHeight + '');
        });

        return () => {
            refresh.context.timeout && clearTimeout(refresh.context.timeout);
            swRegistration.off('refresh', refresh.callback);
            OfflineStore.off('change', change);
            router.events.off('routeChangeStart', onStartNavigate);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const detectOsThemeMode = (e: any) => {
            const osThemeMode = e.matches
                ? KnownThemes.dark
                : KnownThemes.light;

            if (!themeMode) {
                // theme was not selected yet, so let's use OS detected theme
                // by default, otherwise keep the theme selected by a user
                OfflineStore.set(OfflineStore.THEME, osThemeMode, true);
                setPrefThemeMode(osThemeMode);
            }
        };

        if (window.matchMedia) {
            window.matchMedia('(prefers-color-scheme: dark)')
                .addEventListener('change', detectOsThemeMode);
        }

        if (window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            if (!themeMode) {
                // initial detection of OS dark theme mode, as long as light is
                // our default choice by code
                OfflineStore.set(OfflineStore.THEME, KnownThemes.dark, true);
                setPrefThemeMode(KnownThemes.dark);
            }
        }

        return () => {
            if (window.matchMedia) {
                window.matchMedia('(prefers-color-scheme: dark)')
                .removeEventListener('change', detectOsThemeMode);
            }
        };
    }, [themeMode]);

    if (themeMode === KnownThemes.dark) {
        // @ts-ignore
        import('./styles-dark.css');
    }

    const content = Component.PageLayout
        ? <Component.PageLayout>
            <Component { ...pageProps } />
        </Component.PageLayout>
        : <Component { ...pageProps } />;

    return <React.Fragment>
        {/* The _app <Head> component is next/head */}
        <Head>
            <meta name="viewport" content="width=device-width,
                initial-scale=1.0"
            />
            <meta name="description" content={
                `${ config?.consumerName || '' }, Your Online Loan Solution`
            } />
            <meta
                name="keywords"
                content={
                    'instalment loans, payday loans, online loan solution, ' +
                    'flex-pay installment loans, credit rating, overdraft ' +
                    'fees, personal loans'
                } />
        </Head>
        <ConfigProvider config={ config }>
            <MenuProvider>
                <LocalizationProvider dateAdapter={ AdapterDateFns }>
                    <ThemeComponent
                        initMode={ prefThemeMode }
                        font={ roboto }
                        userAgent={ userAgent }
                        ww={ ww }
                        wh={ wh }
                    >
                        { highestPriorityEnabledScreen
                            ? <MaintenanceComponent
                                { ...highestPriorityEnabledScreen }
                                phoneNumber={
                                    config.
                                        consumerOperationsDetails?.
                                        phoneNumber
                                }
                            />
                            : <AuthorizedContext.Provider
                                value={ !!authorized }
                            >
                                <AccountInfoProvider
                                    onChange={ setCustomer }>
                                    <NotificationsProvider>
                                        { content }
                                    </NotificationsProvider>
                                </AccountInfoProvider>
                            </AuthorizedContext.Provider>
                        }
                    </ThemeComponent>
                </LocalizationProvider>
            </MenuProvider>
        </ConfigProvider>
    </React.Fragment>;
}

App.getInitialProps = async (appContext: AppProps) => {
    const appProps = await NextApp.getInitialProps(
        appContext as unknown as AppContext,
    );
    const { req, res } = (appContext as any).ctx;
    const { origin } = absoluteUrl(req);
    const host = (req?.headers || {})['host'];
    const [, version] = host && host.split('.') || [];
    const config = await getRuntimeConfig(origin, version);
    let themeMode: KnownThemes | undefined = undefined;
    let authorized = false;

    if (!config) {
        return <React.Fragment>OK</React.Fragment>;
    }

    if (res && await accessDenied(req)) {
        res.statusCode = 403;
        res.end(null);

        return null;
    }

    let cookie = req?.headers['cookie'];
    const deviceIdCookieName = 'deviceId';
    const trackingSessionIdCookieName = 'trackingSessionId';
    let deviceId = cookie
        ? Cookie.get(deviceIdCookieName, cookie)
        : undefined;
    let trackingSessionId = cookie
        ? Cookie.get(trackingSessionIdCookieName, cookie)
        : undefined;

    if (req && res) {
        if (!deviceId) {
            deviceId = uuid();
            cookie = `${
                cookie
            }; ${
                deviceIdCookieName
            }=${
                deviceId
            }`;
            req.headers['cookie'] = cookie;
        }
        if (!trackingSessionId) {
            trackingSessionId = uuid();
            cookie = `${
                cookie
            }; ${
                trackingSessionIdCookieName
            }=${
                trackingSessionId
            }`;
            req.headers['cookie'] = cookie;
        }

        const trackingSessionExpirationDate = new Date();
        trackingSessionExpirationDate.setTime(
            trackingSessionExpirationDate.getTime() + 30 * 60 * 1000,
        );

        const formatCookie = (name: string, value: string, expires: string) =>
            `${ name }=${ value }; Path=/; Expires=${ expires };`;

        res.setHeader('set-cookie', [
            formatCookie(
                deviceIdCookieName,
                deviceId,
                'Fri, 31 Dec 9999 23:59:59 GMT',
            ),
            formatCookie(
                trackingSessionIdCookieName,
                trackingSessionId,
                trackingSessionExpirationDate.toUTCString(),
            ),
        ]);

        trackRequest(req);

        try {
            await assignDomainString(req, res, origin);

            const { getSession } = await import('../common/session');
            const session = await getSession(req, res);

            themeMode = session.theme || themeMode;
            authorized = isAuthorized(req);
        } catch (err) {
            asyncLogger.error(err);
        }

        initAuthUtils({
            redis: { host: CACHE_HOST, port: CACHE_PORT, prefix: 'ws' },
            refreshToken: async token => {
                const { data: { refreshToken }, headers } =
                    await GqlClient.gqlRequest(
                        `mutation($token: String!) {
                            refreshToken(token: $token)
                        }`,
                        { req, variables: { token } },
                    );
                return { refreshedToken: refreshToken, headers };
            },
            logger: asyncLogger,
        }).catch(asyncLogger.error);
    }

    if (themeMode === KnownThemes.dark) {
        // @ts-ignore
        import('./styles-dark.css');
    }

    const userAgent = req?.headers['user-agent'] || '';

    return {
        ...appProps,
        config,
        userAgent,
        authorized,
        themeMode,
        swResources: await serviceWorkerInitializer.getSwResources(req),
        ww: Cookie.get('ww', cookie || ''),
        wh: Cookie.get('wh', cookie || ''),
        deviceId,
        tokenExists: !!Cookie.get('authToken', cookie),
    };
};

export default App;
